// React
import React from "react";

// Material
import { Card, Typography, Grid, Box } from "@material-ui/core";

// Styles
import { createStyles, makeStyles } from "@material-ui/core/styles";
import "./CardDashboard.scss";

// Helpers
import { getSecondsFormattedOnlyHours } from "../../helpers/date-fomatter.helper";
import ContentLoader from "react-content-loader";
import { Domain } from "../../models/domain.model";

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    content: {},
    title: {
      color: "#263c46",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "-0.43px"
    },
    titleTime: {
      fontSize: 15,
      textAlign: "center",
      color: "#8d919a"
    },
    confirmedTime: {
      color: "#77C35E"
    },
    time: {
      fontSize: 15,
      fontWeight: 700
    }
  })
);

/**
 * Card dashboard props
 */
interface CardDashboardProps {
  domain: Domain;
  duration: number;
  fake?: boolean;
}

/**
 * Card dashboard
 */
const CardDashboard = ({ duration, domain, fake }: CardDashboardProps) => {
  const classes = useStyles();

  return (
    <Card>
      {fake ? (
        <ActivityLoader />
      ) : (
        <div style={{ padding: 22 }}>
          <Box mb={2}>
            <Grid
              container={true}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <i className={`ic-follow ic-follow-${domain.learningId}`} />
              <Typography variant="h6" className={classes.title}>
                {domain.title}
              </Typography>
            </Grid>
          </Box>
          <Grid
            container={true}
            spacing={2}
            justifyContent="space-between"
            className={classes.content}
          >
            <Grid item={true}>
              <Typography variant="body1" className={classes.titleTime}>
                Temps de formation réalisé
              </Typography>
            </Grid>

            <Grid item={true}>
              <Typography
                variant="body1"
                className={`${classes.time} ${classes.confirmedTime}`}
              >
                {getSecondsFormattedOnlyHours(duration)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </Card>
  );
};

export default React.memo(CardDashboard);

const ActivityLoader = () => (
  <ContentLoader
    height={500}
    width={920}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#e3e3e3"
  >
    <rect x="20" y="20" rx="5" ry="5" width="880" height="500" />
  </ContentLoader>
);
