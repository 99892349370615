// React
import React, { useEffect, useState, memo } from "react";
import { withRouter } from "react-router-dom";

import ContentLoader from "react-content-loader";
import { Helmet } from "react-helmet";

// Material
import { createStyles, makeStyles, Theme } from "@material-ui/core";

// Components
import BaseSkillsItem from "../../features/BaseSkillsItem/BaseSkillsItem";
import ActivityTime from "../../features/ActivityTime/ActivityTime";
import StarterQuizCard from "../Quiz/QuizStarterCard";

// Models
import { Category } from "../../models/category.model";
import { mapCategory } from "../../services/category.service";

// Providers
import { useSubjectState } from "../Subject/Subject.provider";

// Keycloak
import Box from "@material-ui/core/Box";
import LockedBanner from "../../features/LockedBanner/LockedBanner";

// GraphQl
import { useDispatch, useSelector } from "react-redux";
import { useBaseSkillLazyQuery } from "../../graphql";
import { StoreState } from "../../store";
import { StarterQuizListItem } from "../../models/Activity.model";
import { canAccessDomain } from "../../services/user.service";
import { setErrorToHandleError } from "../../store/errorHandler/actions";
import { getDomainBySlug } from "../../helpers/domain.helper";
import { DEFAULT_DOMAIN, Domain, DOMAINS } from "../../models/domain.model";
import { User } from "../../models/user.model";

/**
 * Get category root
 * @param children
 * @param id
 */
const getCategoryRoot = (children: Category[], id: string): any => {
  let found: Category | undefined;
  if (children) {
    for (const item of children) {
      if (item._id === id) {
        return item;
      } else if (item.childrensMapped) {
        found = getCategoryRoot(item.childrensMapped, id);
        if (found) {
          return item;
        }
      }
    }
  }
};

/**
 * Get category to open
 * @param categoryList
 * @param idSubjectSelected
 */
const getCategoryToOpen = (
  categoryList: Category[],
  idSubjectSelected: string
): any => {
  const categoryRootSelected: Category = getCategoryRoot(
    categoryList,
    idSubjectSelected
  );

  return categoryRootSelected ? categoryRootSelected : -1;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up("md")]: {
        paddingRight: 100,
        paddingLeft: 100
      }
    }
  })
);
/**
 * BaseSkills component
 */
const BaseSkills = withRouter(({ match }) => {
  /** GraphQL */
  const [getBaseSkill, { data }] = useBaseSkillLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "cache-and-network"
  });
  /** Classes */
  const classes = useStyles();
  /** Current domain */
  const [domain, setDomain] = useState<Domain>(DEFAULT_DOMAIN);
  /** Category list state */
  const [category, setCategory] = useState<Category>();
  /** Use subject */
  const { subjectSelected } = useSubjectState();
  /** loading state */
  const [loading, setLoading] = useState(true);
  /** Category list opened */
  const [categoryListOpened, setCategoryListOpened] = useState<Category[]>([]);
  /** show locked notification state */
  const [showLockedNotification, setShowLockedNotification] = useState(false);
  /** Starter quiz */
  const starterQuizList = useSelector(
    (state: StoreState) => state.starterQuizList
  );
  const [starterQuiz, setStarterQuiz] = useState<StarterQuizListItem>();
  /** User state */
  const user: User | null = useSelector((state: StoreState) => state.user);
  /** Dispatch */
  const dispatch = useDispatch();

  /**
   * BaseSkillLoader
   */
  const BaseSkillLoader = () => (
    <ContentLoader
      height={160}
      width={700}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#e3e3e3"
    >
      <rect x="20" y="10" rx="5" ry="5" width="650" height="25" />
      <rect x="20" y="40" rx="5" ry="5" width="650" height="25" />
      <rect x="20" y="70" rx="5" ry="5" width="650" height="25" />
    </ContentLoader>
  );

  /**
   * Initial useEffect
   */
  useEffect(() => {
    const domain = getDomainBySlug(match.params.domainSlug);
    if (canAccessDomain(domain.learningId)) {
      setDomain(domain);
      setLoading(true);
      setStarterQuiz(starterQuizList[domain.learningId]);
      getBaseSkill({
        variables: {
          idOrPath: `,grf,formations,contenu-premium,${DOMAINS.find(domain => domain.slug === match.params.domainSlug)?.learningId}`
        }
      });
    } else {
      dispatch(setErrorToHandleError(true, 401));
    }
  }, []);

  useEffect(() => {
    if (data?.category) {
      setCategory(
        mapCategory(
          data.category,
          user?.subscription.accessTreeEl[data.category.learningId],
          user?.isSuperAdmin
        )
      );
      setLoading(false);
    } else if (data) {
      setLoading(false);
      dispatch(setErrorToHandleError(true, 404));
    }
  }, [data]);

  const activateLockedNotification = () => {
    setShowLockedNotification(true);
    setTimeout(() => {
      setShowLockedNotification(false);
    }, 7500);
  };

  /**
   * Use effect scroll page
   */
  useEffect(() => {
    if (category?.childrensMapped) {
      const newCategoryListOpened: Category[] = [];
      if (
        category.childrensMapped.length &&
        subjectSelected &&
        subjectSelected !== -1
      ) {
        newCategoryListOpened.push(
          getCategoryToOpen(category.childrensMapped, subjectSelected)
        );

        while (
          newCategoryListOpened[newCategoryListOpened.length - 1]
            .childrensMapped
        ) {
          newCategoryListOpened.push(
            getCategoryToOpen(
              newCategoryListOpened[newCategoryListOpened.length - 1]
                .childrensMapped!,
              subjectSelected
            )
          );
        }
      }
      setCategoryListOpened(newCategoryListOpened);
    }
  }, [category, subjectSelected]);

  return (
    <div id="baseSkills">
      {showLockedNotification && <LockedBanner />}
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>{domain.title} - Socle de compétences</title>
      </Helmet>
      <div className={classes.container}>
        {loading || !category ? (
          <BaseSkillLoader />
        ) : (
          <React.Fragment>
            <Box marginBottom={20}>
              <ActivityTime
                isActivityList={false}
                domain={domain}
                confirmedTime={category.confirmedTime}
                globalTime={category.estimatedTime}
                globalTimeTitleCustom={"Temps global du domaine"}
              />
              {starterQuiz && (
                <div style={{ marginBottom: 15 }}>
                  <StarterQuizCard
                    id={starterQuiz._id}
                    isStarted={starterQuiz.isStarted}
                    domain={domain}
                  />
                </div>
              )}
              {(category.childrensMapped ?? []).map((category: Category) => (
                <div id={category._id} key={category._id}>
                  <BaseSkillsItem
                    key={category._id}
                    category={category}
                    isLevelCategory={true}
                    categoryListOpened={categoryListOpened}
                    domain={domain}
                    locked={!!starterQuiz}
                    onLockedClick={activateLockedNotification}
                  />
                </div>
              ))}
            </Box>
          </React.Fragment>
        )}
      </div>
    </div>
  );
});

export default memo(BaseSkills);
