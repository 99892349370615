import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";

// Components
import ActivityList from "../../features/ActivityList/ActivityList";
import { FakeCardActivity } from "../../features/CardActivity/CardActivity";
import ActivityTime from "../../features/ActivityTime/ActivityTime";
import NoResult from "../../features/NoResult/NoResult";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../store";
import { Category } from "../../models/category.model";
import { mapCategory } from "../../services/category.service";
import { useCategorySubjectLazyQuery } from "../../graphql";

import { setErrorToHandleError } from "../../store/errorHandler/actions";
import { User } from "../../models/user.model";

/**
 * Subject component
 */
const Subject = withRouter(({ match }) => {
  /** GraphQL */
  const [getSubject, { data }] = useCategorySubjectLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "cache-and-network"
  });

  /** Subject and activity state */
  const [category, setCategory] = useState<Category>();
  /** Loading */
  const [loading, setLoading] = useState(true);
  /** Dispatch store */
  const dispatch = useDispatch();
  /** Match */
  const { params } = match;
  /** Starter quiz */
  const starterQuizList = useSelector(
    (state: StoreState) => state.starterQuizList
  );
  /** User state */
  const user: User | null = useSelector((state: StoreState) => state.user);

  /** Fetch Activity list */
  const fetchData = async () => {
    getSubject({
      variables: {
        idOrPath: params.subjectId
      }
    });
  };

  /**
   * Use effect get activity list
   */
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    if (data?.category) {
      const category = mapCategory(
        data.category,
        undefined,
        user?.isSuperAdmin
      );
      //! GESTION DE DROIT A REMETTRE
      /* if (
        starterQuizList[category.domain.learningId] || !canAccessSubject(category)
      ) {
        dispatch(setErrorToHandleError(true, 401));
      } else {
        setCategory(category);
        setLoading(false);
      } */

        if (
          starterQuizList[category.domain.learningId]
        ) {
          dispatch(setErrorToHandleError(true, 401));
        } else {
          setCategory(category);
          setLoading(false);
        }
    } else if (data) {
      setLoading(false);
    }
  }, [data]);

  return (
    <React.Fragment>
      {category?.domain ? (
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          <title>{category.domain.title + " - " + category.title}</title>
        </Helmet>
      ) : null}
      {loading && !category ? (
        <FakeCardActivity />
      ) : category?.activitiesMapped && category.activitiesMapped.length ? (
        <React.Fragment>
          <ActivityTime
            domain={category.domain}
            confirmedTime={category.confirmedTime}
            globalTime={category.estimatedTime}
          />
          <ActivityList list={category.activitiesMapped} />
        </React.Fragment>
      ) : (
        <NoResult
          titleCustom={
            "Il n'y a actuellement aucune activité rattachée à ce sujet"
          }
          subTitleCustom={" "}
        />
      )}
    </React.Fragment>
  );
});

export default Subject;
