/**
 * Constante
 */
export const CONST = {
  CURRENT_YEAR: new Date().getFullYear(),
  KEYCLOAK: {
    URL: process.env.REACT_APP_KEYCLOAK_URL || "",
    REALM: process.env.REACT_APP_KEYCLOAK_REALM || "",
    CLIENTID: process.env.REACT_APP_KEYCLOAK_CLIENTID || ""
  },
  TIME_ENDPOINT: process.env.REACT_APP_TIME_ENPOINT || "",
  RF_CAC_URL: process.env.REACT_APP_URL_RF_CAC,
  GRAPHQL_ENDPOINT:
    process.env.REACT_APP_GRAPHQL_ENDPOINT || "http://localhost:4000/graphql",
  ALICE_ADAPTER_ENDPOINT:
    process.env.REACT_APP_ALICE_ADAPTER_ENDPOINT ||
    "http://localhost:3002/users",
  OKULUS_URL: process.env.REACT_APP_OKULUS_URL || "http://localhost:4000",
  PDF_GENERATOR_ENDPOINT:
    process.env.REACT_APP_PDF_GENERATOR_ENDPOINT || "http://localhost:3001",
  PAGE_PRODUCTLIST: "https://portail.rf-elearning.com/",
  MONTH_REDUCED: 3,
  GA_ID: process.env.REACT_APP_GA_ID || "UA-110057889-2",
  SURVEY_ID: process.env.REACT_APP_SURVEY_ID || "Wsv3c4ai"
};
