// React
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// Material
import { Card, CardContent, Grid, Hidden, Typography } from "@material-ui/core";

// Styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Helpers
import { isPlurial } from "../../helpers/card.helper";
import clsx from "clsx";

// Models
import { Category } from "../../models/category.model";

// Colors
import {
  useSubjectDispatch,
  useSubjectState
} from "../../views/Subject/Subject.provider";
import { useScrollDispatch } from "../../shared/Scroll.provider";
import { Domain } from "../../models/domain.model";
import { getDomainColor } from "../../helpers/domain.helper";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderLeft: "5px solid",
      borderRadius: 0,
      boxShadow: "none"
    },
    cardContent: {
      padding: 8,
      "&:last-child": {
        paddingBottom: 8
      }
    },
    text: {
      cursor: "pointer",
      textDecoration: "none",
      color: "black"
    },
    title: {
      minHeight: 22,
      fontSize: 16,
      justifyContent: "center",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    subtitle: {
      height: 18,
      letterSpacing: 0.86,
      fontSize: 12,
      justifyContent: "center"
    },
    container: {
      width: "100%",
      marginTop: -26
    },
    dotCardSubject: {
      margin: 2,
      marginRight: 10,
      width: 8,
      height: 8,
      borderRadius: "50%",
      display: "inline-block",
      border: "solid 1.6px"
    },
    dot: {
      margin: 1,
      marginRight: 2,
      width: 8,
      height: 8,
      borderRadius: "50%",
      display: "inline-block",
      backgroundColor: theme.palette.primary.main
    },
    locked: {
      pointerEvents: "none",
      opacity: 0.5,
      cursor: "not-allowed"
    }
  })
);

/**
 * CardSubject props
 */
interface CardSubjectProps {
  category: Category;
  domain: Domain;
  locked?: boolean;
  onLockedClick?: () => void;
}

/**
 * Card subject
 */
const CardSubject = ({
  category,
  domain,
  locked,
  onLockedClick
}: CardSubjectProps) => {
  console.log('cardSubject')
  const classes = useStyles();

  /** If state of category is present change size */
  const sizeCategory = category.state ? 9 : 11;

  /** Subject provider */
  const dispatch = useSubjectDispatch();
  const { subjectSelected } = useSubjectState();

  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();

  const openCategory = (): void => {
    dispatch({
      type: "setIdSubjectSelected",
      idSubjectSelected: category._id
    });
  };

  useEffect(() => {
    if (subjectSelected && subjectSelected === category._id) {
      const cardElement = document.getElementById(category._id.toString());
      if (cardElement) {
        setTimeout(() => {
          dispatchScroll({
            type: "setScrollPosition",
            scrollPosition: cardElement.getBoundingClientRect().top - 150
          });
        }, 100);
      }
    }
  }, []);

  return (
    <div
      onClick={() => locked && onLockedClick && onLockedClick()}
      className={clsx({
        [classes.container]: true
      })}
    >
      <Grid
        className={clsx({
          [classes.locked]: locked
        })}
        container={true}
        alignItems={"center"}
        spacing={2}
        wrap={"nowrap"}
      >
        <Grid item={true} xs={true}>
          <Card
            className={classes.card}
            style={{ borderLeftColor: getDomainColor(domain) }}
          >
            <CardContent className={classes.cardContent}>
              <Grid container={true}>
                <Grid item={true} xs={sizeCategory}>
                  <Hidden smDown={true} implementation="css">
                    <Grid
                      container={true}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item={true}>
                        <Typography
                          className={classes.title}
                          color="textPrimary"
                        >
                          <span
                            className={classes.dotCardSubject}
                            style={{
                              borderColor: getDomainColor(domain)
                            }}
                          />
                          <Link
                            className={classes.text}
                            id={category._id}
                            to={`/${domain.slug}/competences/${category._id}`}
                            onClick={openCategory}
                          >
                            {category.title}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item={true}>
                        <Typography
                          className={`${classes.subtitle}`}
                          color="textSecondary"
                          noWrap={true}
                        >
                          ({category.nbActivities}{" "}
                          {isPlurial(category.nbActivities)})
                        </Typography>
                      </Grid>
                    </Grid>
                  </Hidden>
                  <Hidden mdUp={true} implementation="css">
                    <Grid container={true} direction="column">
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom={true}
                      >
                        <span
                          className={classes.dotCardSubject}
                          style={{
                            borderColor: getDomainColor(domain)
                          }}
                        />
                        <Link
                          className={classes.text}
                          id={category._id}
                          to={`/${domain.slug}/competences/${category._id}`}
                          onClick={openCategory}
                        >
                          {category.title}
                        </Link>
                      </Typography>

                      <Typography
                        color="textSecondary"
                        gutterBottom={true}
                        noWrap={true}
                      >
                        <span className={`${classes.subtitle}`}>
                          ({category.nbActivities}{" "}
                          {isPlurial(category.nbActivities)}){" "}
                        </span>
                        {category.state ? (
                          <React.Fragment>
                            <span className={classes.dot} />
                            <span className={classes.subtitle}>
                              {" "}
                              Nouveauté{" "}
                            </span>
                          </React.Fragment>
                        ) : null}
                      </Typography>
                    </Grid>
                  </Hidden>
                </Grid>

                <Grid item={true} xs={3}>
                  <Grid container={true} justifyContent="center">
                    <Hidden smDown={true} implementation="css">
                      {category.state ? (
                        <Typography color="textSecondary" noWrap={true}>
                          <span className={classes.dot} />{" "}
                          <span className={classes.subtitle}> Nouveauté </span>
                        </Typography>
                      ) : null}
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(CardSubject);
