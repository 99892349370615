// React
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Container,
  Grid,
  Typography,
  Button,
  Hidden,
  Theme
} from "@material-ui/core";

// Material
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

// Lib
import { DatePicker } from "@material-ui/pickers";

// Helper
import { formattedDate } from "../../helpers/date-fomatter.helper";

// Model
import { User } from "../../models/user.model";

// Icon
import GetAppIcon from "@material-ui/icons/GetApp";

// Components
import ColorCircularProgress from "../../features/ColorCircularProgress/ColorCircularProgress";
import Certificate from "../Certificate/Certificate";
import { Domain } from "../../models/domain.model";
import { getPdf } from "../../services/utility.service";
import { useSnackbar } from "notistack";
import { getDomainById } from "../../helpers/domain.helper";
import { useTimePerDomainLazyQuery } from "../../graphql";

/**
 * Use styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  datepickerWrapper: {
    marginLeft: -5
  },
  downloadBtnWrapper: {
    marginRight: -15
  },
  appBar: {
    width: "100%",
    paddingBottom: 15,
    paddingTop: 15,
    top: 0,
    left: 0,
    boxShadow: "inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)",
    backgroundColor: "#f9fdfc"
  },
  title: {
    color: "#18202f",
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: -2
  },
  button: {
    height: 46,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      marginLeft: -30
    }
  },
  buttonTypography: {
    paddingLeft: 6,
    paddingRight: 10
  }
}));

/**
 * CertificatePicker props
 */
interface ICertificatePickerProps {
  user: User;
}

/**
 * Certificate picker component
 */
const CertificatePicker = ({ user }: ICertificatePickerProps) => {
  /** Classes */
  const classes = useStyles();

  /** GraphQL */
  const [getTimePerDomain, { data, error }] = useTimePerDomainLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only"
  });

  /** Loading state */
  const [loading, setLoading] = useState(false);

  // Picker limit dates
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();

  // Selected dates
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());

  // Offset height
  // TODO: Dynamically set offsetHeight based on AppBar's height
  const [offsetHeight] = useState<number>(110);

  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();

  // Use effect
  useEffect(() => {
    if (user?.subscription.periods.length) {
      let min = new Date();
      let max = new Date();
      for (const period of user.subscription.periods) {
        const start = new Date(period.start);
        const end = new Date(period.end);
        min = start < min ? start : min;
        max = end > max ? end : max;
      }
      const now = new Date();
      max = max > now ? now : max;

      setMinDate(min);
      setMaxDate(max);
      setSelectedStartDate(min);
      setSelectedEndDate(max);
    }
  }, [user]);

  const isDateDisabled = (date: MaterialUiPickersDate): boolean => {
    if (!user || !date) {
      return false;
    }

    return !user.subscription.periods.some(
      period =>
        date.toDate() >= new Date(period.start) &&
        date.toDate() <= new Date(period.end)
    );
  };

  const handleDownload = () => {
    setLoading(true);
    getTimePerDomain();
  };

  const generateCertificate = async (
    timesPerDomain: { domain: Domain; duration: number }[]
  ) => {
    try {
      await getPdf(
        <Certificate
          timesPerDomain={timesPerDomain}
          fullName={user.lastName + " " + user.firstName}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
        />,
        `Attestation-RF-e-Learning-${formattedDate(
          selectedStartDate,
          "-"
        )}-${formattedDate(selectedEndDate, "-")}-${user.firstName}-${
          user.lastName
        }.pdf`
      );
    } catch (e) {
      enqueueSnackbar(
        "Une erreur est survenue pendant la génération de l'attestation",
        {
          variant: "error"
        }
      );
    }
  };

  useEffect(() => {
    if (data) {
      generateCertificate([
        {
          domain: getDomainById(812),
          duration: data.DOMAIN_ACCOUNTING?.confirmedDuration ?? 0
        },
        {
          domain: getDomainById(930),
          duration: data.DOMAIN_TAX?.confirmedDuration ?? 0
        },
        {
          domain: getDomainById(1073),
          duration: data.DOMAIN_SOCIAL?.confirmedDuration ?? 0
        },
        {
          domain: getDomainById(1123),
          duration: data.DOMAIN_PAYROLL?.confirmedDuration ?? 0
        },
        {
          domain: getDomainById(1203),
          duration: data.DOMAIN_BUSINESS?.confirmedDuration ?? 0
        },
        {
          domain: getDomainById(1374),
          duration: data.DOMAIN_HERITAGE?.confirmedDuration ?? 0
        },
        {
          domain: getDomainById(1380),
          duration: data.DOMAIN_OFFICE?.confirmedDuration ?? 0
        }
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (error) setLoading(false);
  }, [error]);

  return (
    <>
      <AppBar position="absolute" className={classes.appBar}>
        <Container style={{ margin: "0 auto" }} maxWidth="md">
          <Typography
            component="p"
            className={classes.title}
            gutterBottom={true}
            noWrap={true}
          >
            Mon attestation
          </Typography>
          <Grid
            className={classes.datepickerWrapper}
            container={true}
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item={true} xs={4}>
              <DatePicker
                variant="dialog"
                label="Début"
                value={selectedStartDate}
                format="DD/MM/YYYY"
                cancelLabel="Annuler"
                shouldDisableDate={isDateDisabled}
                minDate={minDate}
                maxDate={selectedEndDate || maxDate}
                onChange={(date: MaterialUiPickersDate | null) => {
                  setSelectedStartDate(date!.toDate());
                }}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <DatePicker
                label="Fin"
                value={selectedEndDate}
                format="DD/MM/YYYY"
                cancelLabel="Annuler"
                shouldDisableDate={isDateDisabled}
                minDate={selectedStartDate || maxDate}
                maxDate={maxDate}
                onChange={(date: MaterialUiPickersDate | null) => {
                  setSelectedEndDate(date!.toDate());
                }}
              />
            </Grid>
            <Grid
              className={classes.downloadBtnWrapper}
              item={true}
              xs={2}
              md={3}
            >
              <Button
                color="primary"
                fullWidth={true}
                variant="contained"
                className={classes.button}
                disabled={!selectedStartDate || !selectedEndDate}
                onClick={handleDownload}
              >
                {loading ? (
                  <ColorCircularProgress ligth={true} />
                ) : (
                  <GetAppIcon />
                )}
                <Hidden smDown={true}>
                  <Typography
                    className={classes.buttonTypography}
                    component="span"
                  >
                    Télécharger
                  </Typography>
                </Hidden>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <div style={{ height: offsetHeight }} />
    </>
  );
};

export default React.memo(CertificatePicker);
