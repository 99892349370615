/**
 * Domain interface
 */
export interface Domain {
  learningId: DomainId;
  title: string;
  slug: string;
  fullPath: string;
  tag: string;
}

export const DOMAINS: Domain[] = [
  {
    title: "Comptabilité",
    slug: "comptabilite",
    fullPath: ",grf,formations,contenu-premium,comptabilite",
    learningId: 812,
    tag: "COMPTABILITE"
  },
  {
    title: "Fiscalité des entreprises",
    slug: "fiscalite-des-entreprises",
    fullPath: ",grf,formations,contenu-premium,fiscalite-des-entreprises",
    learningId: 930,
    tag: "FISCALITE DES ENTREPRISES"
  },
  {
    title: "Droit du travail",
    slug: "droit-du-travail",
    fullPath: ",grf,formations,contenu-premium,droit-du-travail",
    learningId: 1073,
    tag: "DROIT DU TRAVAIL"
  },
  {
    title: "Gestion de la paye",
    slug: "gestion-de-la-paye",
    fullPath: ",grf,formations,contenu-premium,gestion-de-la-paye",
    learningId: 1123,
    tag: "GESTION DE LA PAIE"
  },
  {
    title: "Droit des affaires",
    slug: "droit-des-affaires",
    fullPath: ",grf,formations,contenu-premium,droit-des-affaires",
    learningId: 1203,
    tag: "DROIT DES AFFAIRES"
  },
  {
    title: "Gestion de patrimoine",
    slug: "gestion-de-patrimoine",
    fullPath: ",grf,formations,contenu-premium,gestion-de-patrimoine",
    learningId: 1374,
    tag: "GESTION DE PATRIMOINE"
  },
  {
    title: "Performance du cabinet",
    slug: "performance-du-cabinet",
    fullPath: ",grf,formations,contenu-premium,performance-du-cabinet",
    learningId: 1380,
    tag: "PERFORMANCE DU CABINET"
  }
];

export const DEFAULT_DOMAIN: Domain = {
  learningId: 0,
  title: "Domaine",
  slug: "domaine",
  fullPath: ",grf,formations,contenu-premium",
  tag: "DOMAINE"
};

export enum DomainId {
  DOMAIN_ACCOUNTING = 812,
  DOMAIN_TAX = 930,
  DOMAIN_SOCIAL = 1073,
  DOMAIN_PAYROLL = 1123,
  DOMAIN_BUSINESS = 1203,
  DOMAIN_HERITAGE = 1374,
  DOMAIN_OFFICE = 1380,
  DEFAULT = 0
}

export enum DomainColor {
  "#f9cc00" = 812,
  "#0572b1" = 930,
  "#e51049" = 1073,
  "#ec6d18" = 1123,
  "#07ad95" = 1203,
  "#c35ea0" = 1374,
  "#7ebc4e" = 1380
}
