// React
import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import ContentLoader from "react-content-loader";

// Material
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Hidden,
  LinearProgress,
  CardActions,
  Paper,
  IconButton,
  Snackbar,
  Chip
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles
} from "@material-ui/core/styles";

// Services
import { getIsSuperAdmin } from "../../services/user.service";

// Colors
import { yellow, grey } from "@material-ui/core/colors";

// Images
import clockIcon from "../../assets/ic-estime.svg";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";

// Models
import { Activity, ActivityState } from "../../models/Activity.model";
import { getActivityWord, isQuiz } from "../../services/activity.service";

// Helpers
import {
  formatStringToDate,
  secondsToMinutes
} from "../../helpers/date-fomatter.helper";
import clsx from "clsx";

import "../../styles/progressbar.scss";

// Providers
import {
  useActivityDispatch,
  useActivityState
} from "../../shared/Activity.provider";

// Components
import TimeIndicator from "../../features/TimeIndicator/TimeIndicator";
import ColorCircularProgress from "../../features/ColorCircularProgress/ColorCircularProgress";

// Keycloak
import { cleanAccentLabel } from "../../helpers/card.helper";
import { useScrollDispatch } from "../../shared/Scroll.provider";
import { Status } from "../../graphql";
import { store } from "../../store/createStore";
import { useAuthentication } from "@dsk-lib/user";
import { getDomainColor } from "../../helpers/domain.helper";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noEvent: {
      pointerEvents: "none"
    },
    cardContent: {
      paddingBottom: 0
    },
    cardActions: {
      paddingTop: 0
    },
    dot: {
      margin: 1,
      marginRight: 10,
      width: 8,
      height: 8,
      borderRadius: "50%",
      display: "inline-block"
    },
    dotUpdate: {
      backgroundColor: yellow[600]
    },
    dotNew: {
      backgroundColor: theme.palette.primary.main
    },
    wrapper: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    wrapperContent: {
      margin: theme.spacing(1),
      position: "relative"
    },
    card: {
      padding: theme.spacing(0.5, 0.5),
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f0f0f0"
      }
    },
    chipDomain: {
      color: "white",
      margin: theme.spacing(0.5)
    },
    chipNature: {
      backgroundColor: "#565554",
      color: "white",
      margin: theme.spacing(0.5),
      textTransform: "capitalize"
    },
    chipType: {
      backgroundColor: "white",
      color: "black",
      margin: theme.spacing(0.5),
      textTransform: "capitalize"
    },
    title: {
      fontSize: 16,
      justifyContent: "center"
    },
    titleTime: {
      fontWeight: 500
    },
    cardFooter: {
      margin: theme.spacing(0.5)
    },
    clockIcon: {
      display: "flex",
      width: 24,
      height: 24
    },
    stateType: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start"
      }
    },
    date: {
      fontSize: 14
    },
    circularLoader: {
      color: grey[300],
      zIndex: 1
    },
    circulartext: {
      position: "absolute",
      top: 14,
      width: 50,
      height: 20,
      textAlign: "center",
      display: "block",
      color: theme.palette.primary.main,
      zIndex: 2
    },
    circular: {
      position: "absolute",
      color: theme.palette.primary.main,
      zIndex: 2
    },
    circularBackground: {
      color: grey[300],
      zIndex: 1
    },
    progressContainer: {
      position: "relative"
    },
    container: {
      minWidth: 320,
      maxWidth: 1000,
      margin: "0 auto"
    },
    linearProgressBar: {
      root: {
        height: 10,
        backgroundColor: theme.palette.primary.main
      },
      bar: {
        borderRadius: 20,
        backgroundColor: theme.palette.primary.main
      }
    },
    time: {
      color: theme.palette.primary.main,
      margin: theme.spacing(1)
    },
    cardDownload: {
      "&:hover": {
        backgroundColor: "#f0f0f0"
      },
      cursor: "pointer",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        borderTop: "1px dashed gray"
      },
      [theme.breakpoints.up("md")]: {
        borderLeft: "1px dashed gray"
      }
    },
    iconDownload: {
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1),
        justifyContent: "flex-end"
      },
      [theme.breakpoints.up("md")]: {
        justifyContent: "center"
      }
    },
    downloadText: {
      margin: theme.spacing(1),
      lineHeight: 20,
      display: "inline-block"
    },
    maxHeight: {
      height: "100%"
    },
    locked: {
      pointerEvents: "none",
      opacity: 0.5,
      cursor: "not-allowed"
    }
  })
);

/**
 * Border linear progress
 */
const BorderLinearProgress = withStyles({
  root: {
    width: 50,
    height: 8,
    borderRadius: 20,
    backgroundColor: "#dddfdf"
  },
  bar: {
    borderRadius: 20
  }
})(LinearProgress);

/**
 * Card activity props
 */
interface CardActivityProps {
  activity: Activity;
  locked?: boolean;
  onLockedClick?: () => void;
}

/**
 * Card activity
 */
const CardActivity = (props: RouteComponentProps & CardActivityProps) => {
  /** Props */
  const { match, activity, history, locked, onLockedClick } = props;

  /** Keycloak */
  const { fetchWithCredentials } = useAuthentication();
  /** Classes  */
  const classes = useStyles();
  /** isSuperAdmin */
  const [isSuperAdmin, setIsSuperAdmin] = React.useState<boolean>(false);
  /** loadingWordFile */
  const [isLoadingWordFile, setIsLoadingWordFile] = React.useState(false);
  /** errorWordFile */
  const [isErrorWordFile, setIsErrorWordFile] = React.useState(false);
  /** Dispatch activity selected */
  const dispatch = useActivityDispatch();
  /** Use activity selected */
  const { activitySelected } = useActivityState();
  /** Progress state (needed for animation) */
  const [progress, setProgress] = useState(0);
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();

  const activityUrl = `${match.url}/${isQuiz(activity) ? "quiz" : "lesson"}/${
    activity._id
  }`;

  /**
   * Open activity
   */
  const openActivity = (): void => {
    const starterQuiz = store.getState().starterQuizList[
      activity.domain.learningId
    ];
    if (starterQuiz) {
      history.push({
        pathname: `/${activity.domain.slug}/positionnement/${starterQuiz._id}`,
        state: { starterInfo: true }
      });
      return;
    }

    dispatch({
      type: "setIdActivitySelected",
      idActivitySelected: activity._id
    });
    history.push(activityUrl);
  };

  useEffect(() => {
    if (activity._id === activitySelected) {
      const cardElement = document.getElementById(activity._id);
      if (cardElement) {
        setTimeout(() => {
          dispatchScroll({
            type: "setScrollPosition",
            scrollPosition: cardElement.getBoundingClientRect().top - 120
          });
        }, 100);
      }
    }
    setIsSuperAdmin(getIsSuperAdmin());
    setProgress(activity.progress);
  }, [activity]);

  /**
   * Get activity as word file
   */
  const fetchWordFile = async () => {
    setIsLoadingWordFile(true);

    await getActivityWord(activity, activityUrl, fetchWithCredentials)
      .catch(() => {
        setIsErrorWordFile(true);
      })
      .finally(() => setIsLoadingWordFile(false));
  };

  /**
   * Close snack bar
   */
  const handleClose = (
    _event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsErrorWordFile(false);
  };

  return (
    <div
      onClick={() => locked && onLockedClick && onLockedClick()}
      className={classes.container}
    >
      <Grid
        id={activity._id}
        container={true}
        alignItems={"center"}
        spacing={2}
        wrap={"nowrap"}
      >
        <Grid item={true} className={classes.wrapper}>
          <div className={classes.wrapperContent}>
            <span className={classes.circulartext}>{progress}%</span>
            <CircularProgress
              size={50}
              variant="determinate"
              value={progress}
              thickness={4}
              className={classes.circular}
            />
            <CircularProgress
              size={50}
              variant="determinate"
              value={100}
              className={classes.circularBackground}
            />
          </div>
        </Grid>
        <Grid container={true} item={true} xs={true}>
          <Grid
            className={clsx({
              [classes.locked]: locked
            })}
            item={true}
            md={true}
            xs={12}
            onClick={() => openActivity()}
          >
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Hidden mdUp={true} implementation="css">
                  <Grid
                    container={true}
                    alignItems={"center"}
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <Grid item={true}>
                      <Typography
                        noWrap={true}
                        className={classes.date}
                        color="textSecondary"
                      >
                        {formatStringToDate(activity.updatedAt)}
                      </Typography>
                    </Grid>
                    <Grid
                      item={true}
                      container={true}
                      xs={true}
                      justifyContent="flex-start"
                      alignItems={"center"}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        value={progress}
                        color="primary"
                      />
                      <Typography noWrap={true} className={classes.time}>
                        {progress}%
                      </Typography>
                    </Grid>
                  </Grid>
                </Hidden>
                <Grid container={true}>
                  <Grid item={true}>
                    <Chip
                      size="small"
                      label={activity.domain.title}
                      className={classes.chipDomain}
                      style={{
                        backgroundColor: getDomainColor(activity.domain)
                      }}
                    />
                    {activity.nature ? (
                      <Chip
                        size="small"
                        label={cleanAccentLabel(activity.nature)}
                        className={classes.chipNature}
                        variant="outlined"
                      />
                    ) : null}
                    {activity.type ? (
                      <Chip
                        size="small"
                        label={cleanAccentLabel(activity.type)}
                        className={classes.chipType}
                        variant="outlined"
                      />
                    ) : null}
                  </Grid>
                  <Grid
                    container={true}
                    item={true}
                    xs={true}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                  >
                    <Hidden smDown={true} implementation="css">
                      <Typography
                        noWrap={true}
                        className={classes.date}
                        color="textSecondary"
                      >
                        {formatStringToDate(
                          activity.state === ActivityState.UPDATE
                            ? activity.updatedAt
                            : activity.createdAt
                        )}
                      </Typography>
                    </Hidden>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Typography
                      className={classes.title}
                      color="textPrimary"
                      gutterBottom={true}
                    >
                      {activity.status === Status.Draft ||
                      activity.status === Status.Validated
                        ? "[BROUILLON] " + activity.title
                        : activity.title}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Grid
                  container={true}
                  justifyContent="flex-start"
                  spacing={1}
                  className={classes.cardFooter}
                >
                  <Grid item={true} className={classes.clockIcon}>
                    <img src={clockIcon} alt="temps estimé" />
                  </Grid>
                  <Grid container={true} item={true} xs={9} alignItems="center">
                    <TimeIndicator
                      isCategory={false}
                      domain={activity.domain}
                      confirmedTime={`${secondsToMinutes(
                        activity.confirmedTime
                      )} min`}
                      estimedTime={`${secondsToMinutes(
                        activity.estimatedTime ?? 0
                      )} min`}
                      isQuiz={isQuiz(activity)}
                      validatedQuestionCount={activity.validatedQuestionCount}
                      questionCount={
                        isQuiz(activity) ? activity.questionCount : 0
                      }
                    />
                  </Grid>
                  {activity.state ? (
                    <Grid
                      className={classes.stateType}
                      container={true}
                      item={true}
                      xs
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Typography
                        className={classes.date}
                        gutterBottom={true}
                        noWrap={true}
                      >
                        <span
                          className={`${classes.dot} ${
                            activity.state === ActivityState.UPDATE
                              ? classes.dotUpdate
                              : classes.dotNew
                          }`}
                        />
                        {activity.state}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </CardActions>
            </Card>
          </Grid>

          {isSuperAdmin ? (
            <Grid
              item={true}
              md={3}
              xs={12}
              alignItems="stretch"
              container={true}
              onClick={() => fetchWordFile()}
            >
              <Paper className={classes.cardDownload}>
                <Grid
                  container={true}
                  alignContent={"center"}
                  alignItems={"center"}
                  className={classes.maxHeight}
                >
                  <Grid
                    container={true}
                    item={true}
                    xs={2}
                    md={3}
                    justifyContent="center"
                    className={classes.iconDownload}
                  >
                    {isLoadingWordFile ? (
                      <ColorCircularProgress />
                    ) : (
                      <GetAppIcon />
                    )}
                  </Grid>
                  <Grid
                    container={true}
                    item={true}
                    xs={9}
                    md={9}
                    justifyContent="center"
                    wrap="nowrap"
                  >
                    Télécharger le Word
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={isErrorWordFile}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={
          <span id="message-id">
            Il y a eu une erreur lors du téléchargement. Veuillez réessayer
            ultérieurement.
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
};

/**
 * Activity loader
 */
const ActivityLoader = () => (
  <ContentLoader
    height={160}
    width={920}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#e3e3e3"
  >
    <rect x="20" y="20" rx="5" ry="5" width="300" height="25" />
    <rect x="800" y="20" rx="5" ry="5" width="100" height="25" />
    <rect x="20" y="50" rx="5" ry="5" width="650" height="25" />
    <rect x="20" y="120" rx="5" ry="5" width="150" height="25" />
  </ContentLoader>
);

/**
 * Fake card activity
 */
export const FakeCardActivity = () => {
  /** classes */
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${classes.noEvent}`}>
      <Grid container={true} alignItems={"center"} spacing={2} wrap={"nowrap"}>
        <Grid item={true} className={classes.wrapper}>
          <div className={classes.wrapperContent}>
            <CircularProgress
              size={50}
              variant="indeterminate"
              value={0}
              thickness={4}
              className={classes.circularLoader}
            />
          </div>
        </Grid>
        <Grid container={true} item={true} xs={true}>
          <Grid item={true} md={true} xs={12}>
            <Card className={classes.card}>
              <ActivityLoader />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(CardActivity);
