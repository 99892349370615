// React
import React from "react";

// Material
import { Card, CardContent, Grid, Hidden, Typography } from "@material-ui/core";

// Styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// Helpers
import { secondsToMinutes } from "../../helpers/date-fomatter.helper";

// Components
import TimeIndicator from "../../features/TimeIndicator/TimeIndicator";

// Models
import { Category } from "../../models/category.model";
import { Domain } from "../../models/domain.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: 14,
      "&:last-child": {
        paddingBottom: 14
      }
    },
    title: {
      minHeight: 22,
      fontSize: 16,
      justifyContent: "center",
      fontWeight: 600
    },
    subtitle: {
      height: 18,
      letterSpacing: 0.86,
      fontSize: 12,
      justifyContent: "center"
    },
    container: {
      width: "100%",
      zIndex: 9
    },
    circleCategory: {
      borderRadius: "50%",
      backgroundColor: "#f1f4f5"
    },
    dot: {
      margin: 1,
      marginRight: 5,
      width: 8,
      height: 8,
      borderRadius: "50%",
      display: "inline-block",
      backgroundColor: theme.palette.primary.main
    },
    isExpandLess: {
      backgroundColor: "#e5e5e5"
    }
  })
);

/**
 * CardSubCategory props
 */
interface CardSubCategoryProps {
  category: Category;
  isExpandLess: boolean;
  domain: Domain;
}

/**
 * Card subCategory
 */
const CardSubCategory = ({
  category,
  isExpandLess,
  domain
}: CardSubCategoryProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container={true} alignItems={"center"} wrap={"nowrap"}>
        <Grid item={true} xs={true}>
          <Card className={`${isExpandLess ? classes.isExpandLess : null}`}>
            <CardContent className={classes.content}>
              <Grid container={true}>
                <Grid item={true} xs={9}>
                  <Hidden smDown={true} implementation="css">
                    <Grid
                      container={true}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item={true}>
                        <Typography
                          className={classes.title}
                          color="textPrimary"
                        >
                          {category.title}
                        </Typography>
                      </Grid>
                      <TimeIndicator
                        isCategory={true}
                        domain={domain}
                        confirmedTime={`${secondsToMinutes(
                          category.confirmedTime
                        )} min`}
                        nbActivities={category.nbActivities}
                      />
                    </Grid>
                  </Hidden>
                  <Hidden mdUp={true} implementation="css">
                    <Grid container={true} direction="column">
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom={true}
                      >
                        {category.title}
                      </Typography>
                      {category.state ? (
                        <Typography
                          color="textSecondary"
                          gutterBottom={true}
                          noWrap={true}
                        >
                          <span className={classes.dot} />
                          <span className={classes.subtitle}> Nouveauté </span>
                        </Typography>
                      ) : null}
                      <TimeIndicator
                        isCategory={true}
                        domain={domain}
                        confirmedTime={`${secondsToMinutes(
                          category.confirmedTime
                        )} min`}
                        nbActivities={category.nbActivities}
                      />
                    </Grid>
                  </Hidden>
                </Grid>

                <Grid item={true} xs={2}>
                  <Grid container={true} justifyContent="center">
                    <Hidden smDown={true} implementation="css">
                      {category.state ? (
                        <Typography color="textSecondary" noWrap={true}>
                          <span className={classes.dot} />{" "}
                          <span className={classes.subtitle}> Nouveauté </span>
                        </Typography>
                      ) : null}
                    </Hidden>
                  </Grid>
                </Grid>

                <Grid item={true} xs={1}>
                  <Grid
                    container={true}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {isExpandLess ? (
                      <ExpandLess className={classes.circleCategory} />
                    ) : (
                      <ExpandMore className={classes.circleCategory} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(CardSubCategory);
