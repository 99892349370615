// React
import React, { useEffect } from "react";
// Material
import { Container } from "@material-ui/core";
// Component
import Viewers from "../../component/Viewer/Viewers";
import YouTubePlayer from "../../features/Video/YouTubePlayer";
// Styles
import "./Lessons.scss";
// Models
import { Lesson } from "../../models/Activity.model";
import { getYoutubeId } from "../../services/activity.service";
// Helpers
import { validURL } from "../../helpers/url.helper";
import { CONST } from "../../config/constant";
import { useTime } from "./UseTime";
import { LearningTypes } from "@dsk-lib/user";
import { getUserId } from "../../services/user.service";

/**
 * Lesson props
 */
interface LessonProps {
  playTimer: (play: boolean) => void;
  lesson: Lesson;
  fontSize: number;
}

/**
 * Lesson component
 */
const Lessons = ({ playTimer, lesson, fontSize }: LessonProps) => {
  /** Is video */
  const isVideo: boolean = lesson.nature === "VIDEO";
  const connect = useTime({
    url: CONST.TIME_ENDPOINT,
    userID: getUserId(),
    learningType: LearningTypes.Lesson,
    learningCategorie: lesson.domain.learningId.toString(),
    learningProduct: "RF_FORMATION",
    learningID: lesson.learningId,
    limit: lesson.estimatedTime ?? undefined
  });

  /**
   * Handle play timer
   * @param play
   */
  const handlePlayTimer = (play: boolean): void => {
    playTimer(play);
    connect(play);
  };

  /** Use effect to auto play or not timer */
  useEffect(() => {
    playTimer(!isVideo);
  }, []);

  if (
    !lesson.html &&
    lesson.assetLinks?.length === 1 &&
    lesson.assetLinks[0]?.media?.mimeType === "application/pdf"
  ) {
    return (
      <div>
        <Viewers
          url={`${CONST.OKULUS_URL}/${lesson.assetLinks[0]?.media?.okulusId}`}
        />
      </div>
    );
  } else {
    return (
      <Container maxWidth="md">
        <div className="lesson-content" style={{ fontSize: `${fontSize}px` }}>
          {isVideo ? (
            <YouTubePlayer
              videoId={getYoutubeId(lesson) || ""}
              handleOver={() => console.log("over")}
              handlePlayTimer={handlePlayTimer}
            />
          ) : lesson.html && !validURL(lesson.html) ? (
            <div dangerouslySetInnerHTML={{ __html: lesson.html }} />
          ) : null}
        </div>
      </Container>
    );
  }
};

export default React.memo(Lessons);
