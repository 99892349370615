import React from "react";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "@dsk-lib/user";

// Contantes
import { CONST } from "../config/constant";

export const GqlProvider = ({
  children
}: {
  children: JSX.Element;
}): JSX.Element => {
  const client = useApollo(CONST.GRAPHQL_ENDPOINT);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
