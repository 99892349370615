// React
import React from "react";

// Material
import { Grid, Typography, CircularProgress, Hidden } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

// Images
import clockIcon from "../../assets/ic-estime.svg";

// Helpers
import { getSecondsFormattedOnlyHours } from "../../helpers/date-fomatter.helper";
import { Domain } from "../../models/domain.model";
import { getDomainColor } from "../../helpers/domain.helper";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: 320,
      maxWidth: 1000,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: -20,
      marginBottom: theme.spacing(2),
      padding: 4
    },
    wrapperContent: {
      position: "relative"
    },
    timeList: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    timeGrid: {
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "left",
        marginBottom: theme.spacing(0.25)
      }
    },
    timeLabel: {
      letterSpacing: "1.16px",
      color: "#65747b",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    timeValue: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: "-0.35px",
      color: "#263c46",
      [theme.breakpoints.down("sm")]: {
        fontSize: 13
      }
    },
    clockIcon: {
      width: 20,
      height: 20,
      verticalAlign: "sub"
    },
    vrBorderGrid: {
      padding: "10px",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    vrBorder: {
      borderLeft: "solid 1px rgba(0, 0, 0, 0.2)",
      width: "1px",
      height: "13px"
    }
  })
);

/**
 * ActivityTimeProps props
 */
interface ActivityTimeProps {
  isActivityList?: boolean;
  domain: Domain;
  confirmedTime: number;
  globalTime: number;
  globalTimeTitleCustom?: string;
}

/**
 * ActivityTime component
 */
const ActivityTime = ({
  isActivityList,
  domain,
  globalTime,
  confirmedTime,
  globalTimeTitleCustom
}: ActivityTimeProps) => {
  /** classes */
  const classes = useStyles();

  /** Global time title */
  const globalTimeTitle: string = globalTimeTitleCustom
    ? globalTimeTitleCustom
    : `Temps global des activités`;

  /** Determine if we have to add an hidden CircularProgress */
  const hasCircularProgress = isActivityList === undefined ? true : false;

  return (
    <div className={classes.container}>
      <Grid
        className={classes.timeList}
        container={true}
        alignItems={"center"}
        wrap={"nowrap"}
      >
        {hasCircularProgress ? (
          <Hidden smDown={true} implementation="css">
            <Grid item={true}>
              <div className={classes.wrapperContent}>
                <CircularProgress
                  size={50}
                  variant="determinate"
                  value={100}
                  style={{ opacity: 0 }}
                />
              </div>
            </Grid>
          </Hidden>
        ) : null}
        <Grid
          className={classes.timeGrid}
          container={true}
          justifyContent="center"
          alignContent={"center"}
          spacing={1}
        >
          <Grid item={true}>
            <img
              className={classes.clockIcon}
              src={clockIcon}
              alt="temps estimé"
            />
          </Grid>
          <Grid item={true}>
            <Typography
              component="span"
              className={classes.timeLabel}
              color="textSecondary"
              gutterBottom={true}
              noWrap={true}
            >
              Temps de formation réalisé
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography
              component="span"
              className={classes.timeValue}
              gutterBottom={true}
              style={{ color: getDomainColor(domain) }}
            >
              {getSecondsFormattedOnlyHours(confirmedTime)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item={true} className={classes.vrBorderGrid}>
          <div className={classes.vrBorder} />
        </Grid>
        <Grid
          className={classes.timeGrid}
          container={true}
          justifyContent="center"
          alignContent={"center"}
          spacing={1}
          style={{ marginBottom: 0 }}
        >
          <Grid item={true}>
            <img
              className={classes.clockIcon}
              src={clockIcon}
              alt="temps estimé"
            />
          </Grid>
          <Grid item={true}>
            <Typography
              component="span"
              className={classes.timeLabel}
              color="textSecondary"
              gutterBottom={true}
              noWrap={true}
            >
              {globalTimeTitle}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography
              component="span"
              className={classes.timeValue}
              gutterBottom={true}
            >
              {getSecondsFormattedOnlyHours(globalTime)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(ActivityTime);
